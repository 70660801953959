import {createSlice} from "@reduxjs/toolkit";

export const blogSlice = createSlice({
    name: "blog",
    initialState: {
        value:[],
        blogs:[{
            blogID: 1234356,
            topic:'How the Nigerian economy is benefitting from the SMS needs of international brands',
            summary: 'In an interconnected world where services transcend national boundaries, international brands are using messaging services to reach a wider audience and maximize customer engagement. Messaging services including SMS marketing are increasingly viewed as the quickest and most efficient way for brands to communicate directly with their ...',
            category: 'SMS',
            authorName: '',
            dateCreated: '',
            image: 'https://api.geminigroupng.com/upload/blogs/blog2.jpg',
          
            body: `
            <p>In today’s world, where everyone is connected through their phones, businesses can use a powerful tool called SMS messaging. SMS stands for Short Message Service, and it allows you to send short text messages to people. But it’s not just about sending messages; it’s about making your business grow. </p>
            <p><b>What is SMS messaging?</b></p>
            <p>SMS stands for Short Message Service, it is used widely as a text messaging service that is built for most mobile phones and devices. It allows the users to receive as well as send short messages over a cellular network. SMS messages have a standard length of 160 characters. It is widely used in business and personal communication. SMS uses Communication protocols to enable the exchange of SMS between devices. Here are five ways to leverage SMS Messaging to grow your business</p>

            <p><b>1.Write clear and good messages: </b></p>
            <p>When you send an SMS, keep it short and to the point. Make sure it’s relevant to the person receiving it. Personalise messages by using people's names or talking about things they like. Give them a call to action, For example, you can say, “Click this link” or “Reply YES to confirm.” Try different ways of writing messages to see what works best. </p>
            <p><b>2.Make It Personal</b></p>
            <p>Show your customers that you care. Send them personalized SMS messages. Wish them a happy birthday, say thank you after they make a purchase, or invite them to special events. Send exclusive offers and special perks to your most loyal customers through text messages. This could be early access to sales, special events, or insider discounts. When people feel like they're getting special treatment, they'll keep coming back to your business.</p>
            <p><b>3.Engage with Your Customers</b></p>
            <p>People like texting. More than half of consumers prefer texting a customer support agent over making a phone call. Use SMS for things like order updates (shipping notifications, confirmations), surveys, feedback, and appointment reminders. You can also send exclusive offers to loyal customers. You can also use text messages to answer questions and solve problems for your customers. When people need help fast, they'll appreciate being able to text your business for a quick answer. This makes them happy and more likely to come back again.</p>

            <p><b>4.Get People to Sign Up</b></p>
            <p>Before you start sending text messages, you need to get people to agree to receive them. Make it easy for people to sign up on your website, social media, or in your store. Encourage your customers to sign up to receive text updates from your business. Offer them something special, like discounts or early access to sales. Always let people choose if they want to stop getting messages from you. This way, you’ll have a list of engaged customers who want to hear from you.</p>

            <p><b>5.Pick the Right Texting Service</b></p>
            <p>Choose a texting service that fits your business and budget. Look for features like being able to talk back to customers, send lots of messages at once, see who reads your messages, and make messages personal. Find a service that works well with what you already use and helps you talk to customers better. With the proper texting service, you are sure to reach your audience effectively and also properly measure your engagements. </p>

            <p>Remember, SMS marketing is a win-win—it benefits both your business and your customers. By implementing these strategies, you’ll accelerate business growth, foster customer loyalty, and drive meaningful results. Start leveraging SMS messaging today! 😊</p>
            `
        },{
            blogID: 1234365,
            topic:'5 Ways to Leverage SMS Messaging to Accelerate Your Business Growth',
            summary: 'In today’s world, where everyone is connected through their phones, businesses can use a powerful tool called SMS messaging. SMS stands for Short Message Service, ...',
            category: 'SMS',
            authorName: '',
            dateCreated: '',
            image: 'https://api.geminigroupng.com/upload/blogs/blog1.jpg',
          
            body: `
            <p>In an interconnected world where services transcend national boundaries, international brands are using messaging services to reach a wider audience and maximize customer engagement. Messaging services including SMS marketing are increasingly viewed as the quickest and most efficient way for brands to communicate directly with their customers given that they offer high open rates, instant delivery, and a wide reach. These benefits make messaging services an ideal choice for brands looking to engage with customers globally.</p> 
            <p><b>A rapidly growing market</b></p>
            <p>The mobile landscape of Nigeria has undergone remarkable transformations, and SMS has emerged as a power tool for communication for businesses and consumers alike. The value of the SMS market in Nigeria is expansive and has grown to become a vital component of the digital ecosystem in the country. </p>
            <p>In the year 2023, according to industry estimates, the SMS market in Nigeria was valued at over $250 million and with a projection suggesting a compound annual growth of 18% over the next 5 years. This growth can be attributed to the increasing mobile penetration in the country, with over 114 million active mobile subscriptions as of 2023. </p>
            <p>Digital transformation has also been a key driver in the growth of the SMS market, specifically, in the area of business communication as well as user verification. Various services have become essential for securing online transactions and enhancing customer trust like the SMS-based two factor authentication and one-time password (OTP). </p>
            <p>Also, compared to other digital marketing channels, SMS marketing offers a cost-effective solution for businesses, making it an attractive option for both local and international brands.</p>
            <p><b>Beyond high open rates</b></p>
            <p>SMS messages have high open rates, often exceeding 95%. This makes them a highly effective marketing channel for businesses, as it also ensures that a large population of the target audience sees and engages with contents. This leads to increase brand awareness and customer engagement. </p>
            <p>As a result international brands are increasingly leveraging messaging services to connect with local consumers and drive demand. These big-name brands, both global and regional have been sending a significant volume of SMS messages to reach their target audience in Nigeria. According to industry estimates, international brands account for over 35% of the total SMS traffic in Nigeria, with some prominent players sending up to 10 million SMS messages per month. This is driven by several factors which are; </p>
            <ul>
              <li><b>Wide reach: </b>The ubiquity of mobile phones in Nigeria, coupled with the high SMS open rates, allows international brands to reach a diverse demographic across the country effectively. </li>
            <li><b>Direct communication: </b>SMS marketing provides a direct and personal line of communication with customers, fostering stronger brand-consumer relationships. </li>
            <li><b>Cost-effectiveness: </b>SMS marketing is a relatively low-cost option for international brands to engage with Nigerian consumers compared to traditional and many digital marketing channels.</li>
            <li><b>Measurable impact: </b>The ability to track key metrics such as delivery rates, open rates, response rates, and conversion rates enables international brands to measure the impact of their SMS marketing campaigns and refine their strategies accordingly. </li>
            </ul>
            <p><b>Navigating an emerging regulatory landscape</b></p>
            <p>International brands seeking to tap into the Nigerian SMS market face much approach MNOs directly to access routing services. Currently, value-added service (VAS) aggregators in Nigeria are not permitted to push international traffic, limiting the ability of international brands to fully leverage the country's SMS ecosystem and market friendly rates. </p>
            <p>To unlock the full potential of the Nigerian SMS market, regulatory bodies and industry stakeholders must collaborate and find a solution that benefits all parties involved. By allowing VAS aggregators to handle a quota of international traffic, the market can become more accessible to international brands, ultimately driving greater demand and fuelling the growth of the local SMS industry. </p>
            <p>The Nigerian SMS market has emerged as a vital component of the country's digital landscape, with international brands playing a significant role in fuelling local demand. To capitalize on this opportunity, it is essential for regulatory bodies and industry stakeholders to collaborate and address existing bottlenecks, enabling VAS aggregators to handle a portion of international traffic. </p>
             <p>This collaborative approach will unlock the full potential of the Nigerian SMS market, benefiting businesses, consumers, and the overall digital ecosystem. </p>
            `
        }]
    },
    reducers:{
        setBlogs: (state, action)=>{
            state.blogs = action.payload
        },
    }
});

export const { setUser} = blogSlice.actions;

export default blogSlice.reducer;